"use strict";
if (typeof Number.prototype.seconds === 'undefined') {
    Object.defineProperty(Number.prototype, 'seconds', {
        get() {
            return this * 1000;
        },
    });
    Object.defineProperty(Number.prototype, 'minutes', {
        get() {
            return this * (60).seconds;
        },
    });
    Object.defineProperty(Number.prototype, 'hours', {
        get() {
            return this * (60).minutes;
        },
    });
    Object.defineProperty(Number.prototype, 'days', {
        get() {
            return this * (24).hours;
        },
    });
    Object.defineProperty(Number.prototype, 'percent', {
        get() {
            return this / 100;
        },
    });
    Object.defineProperty(Number.prototype, 'b', {
        get() {
            return this * 8;
        },
    });
    Object.defineProperty(Number.prototype, 'KB', {
        get() {
            return this * 1000;
        },
    });
    Object.defineProperty(Number.prototype, 'MB', {
        get() {
            return this * (1000).KB;
        },
    });
    Object.defineProperty(Number.prototype, 'KiB', {
        get() {
            return this * 1024;
        },
    });
    Object.defineProperty(Number.prototype, 'MiB', {
        get() {
            return this * (1024).KiB;
        },
    });
}
if (typeof String.prototype.titleCase === 'undefined') {
    Object.defineProperty(String.prototype, 'titleCase', {
        get() {
            return this.toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        },
    });
}
